.app {
  position: relative;

  width: 100%;
  height: 100%;
  margin: 0 !important;
  padding: 0;

  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.app-left, .app-right {
  height: 100%;  /*allows both columns to span the full height of the browser window*/
  display: flex;
  flex-direction: column;  /*places the left and right headers above the bottom content*/
}

.app-left, .app-top-left {
  flex-shrink: 0;
}
.app-right  {
  flex-grow: 1;
  width: 100%;
  overflow: hidden;
}

.app-bottom {
  flex-grow: 1;  /*ensures that the container will take up the full height of the parent container*/
  overflow-y: auto;
  position: relative;
}

.with-margins {
  margin-top: 1em;
  margin-bottom: 1em;
}

.notice {
  font-style: italic;
}

.large-centered {
  margin: auto;
  max-width: 800px;
}

.app-left {
  background: $grey-lightest; /* Grey lightest? */
}

.empty {
  font-size: 0.75rem;
  text-align: center;
  color: #999;
}

.modal { overflow: visible; }
.modal-card{ overflow: visible; }
.modal.is-active{ z-index: 1040;}

.content {
  dl {
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    width: 100%;
    overflow: visible;
    margin: 0;
  }

  dl dt {
    flex: 0 0 50%;
    text-overflow: ellipsis;
    overflow: hidden;
    margin: 0;

    font-weight: bold;
    color: #444;

    padding-bottom: 0.4em;
    border-bottom: solid 1px  $grey-lightest;
    margin-bottom: 0.4em;
  }

  dl dd {
    flex:0 0 50%;
    margin-left: auto;
    text-align: left;
    text-overflow: ellipsis;
    overflow: hidden;

    padding-bottom: 0.4em;
    border-bottom: solid 1px  $grey-lightest;
    margin-bottom: 0.4em;
  }
}

aside.menu {
  padding: 0.5em;
  overflow: auto;

  hr {
    background: $grey-lightest;
  }
}

.for-select {

  .choices__inner {
    border-radius: 3px;

    border: solid 1px #dbdbdb;
    background: transparent;

    &:hover{ border: solid 1px #b5b5b5; }
  }

  &.is-danger { .choices__inner { border: solid 1px hsl(348, 100%, 61%); } }
}

//@media (min-width: 640px) {
.choices__list--dropdown {
  z-index: 1001;
  .choices__item--selectable { padding-right: 10px !important; }
}
//}

tr.is-danger {
  color: $danger;

  a {
    color: #adadfd !important;
    text-decoration: underline;
    font-weight: bold;

    &:hover {
      color: #ddddfd !important;
    }
  }

  &:nth-child(even) {
    color: darken(hsl(348, 100%, 61%), 10%) !important;
  }

  &:hover {
    color: darken(hsl(348, 100%, 61%), 25%) !important;
  }
}

text-area.is-danger { border: solid 1px hsl(348, 100%, 61%); }

.nav-container {
  display: flex;
  flex-direction: column;
  height: 100%;

  overflow: hidden;

  .menu {
    flex-grow: 1;

  }

}

.nav-floating {
  display: block;
  position: absolute;
  top: 0; left: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  overflow-y: auto;
  background-color: $grey-lightest;
  z-index: 10001;

  .return {
    font-size: 1.5em;
    padding: 0.5em;

    position: absolute;

    top: -5px;
    left: 4px;
  }


  ul {
    text-align: center;

    .menu-label{
      font-size: 1.25em;
    }
  }

  li a{
    border-top: solid 1px #ddd;
  }

}

.full-height { min-height: 100%; }