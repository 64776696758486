.right-panel-overlay {
  display: none;

  z-index: 1000;

  box-sizing: border-box;

  position: fixed;

  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  background: rgba(rgb(36, 18, 18), 0.3);

  &.open {
    display: block;
  }
}


.right-panel-previous {
  position: absolute;

  left: 10px;
  top : 10px;

  color: white;
  background-color: rgba(black, 0.1666);

  font-size: 24px;
  font-weight: bold;

  line-height: 14px;

  width: 16px;
  height: 16px;

  text-align: center;


  padding: 8px;

  box-sizing: content-box;

  border-radius: 50%;
}

.right-panel-close {
  position: absolute;

  left: auto;
  right: 10px;
  top : 10px;

  color: white;
  background-color: rgba(black, 0.1666);

  font-size: 24px;
  font-weight: bold;

  line-height: 14px;

  width: 16px;
  height: 16px;

  text-align: center;


  padding: 8px;

  box-sizing: content-box;

  border-radius: 50%;
}

.right-panel-container{
  position: fixed;
  top: 0;
  right: 0;

  z-index: 1001;

  width: 100%;
  animation-fill-mode: forwards;
  transition: display 0.5s ease-in-out;
  overflow: hidden;
  height: 100vh;

  .right-panel {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    z-index: 35;

    background: white;

    box-shadow: 0 0 15px rgba(black, 0.3);

    transition: right 0.5s ease-in-out;
    box-sizing: border-box;

    img { width: 100%; }

    .content {
      padding: 1em;
      box-sizing: border-box;
    }

    &>div { padding: 1em; }

    &.closed { right: -100%; }
    &.open { right: 0%; }
  }

  &.closed { display: none; }
  &.open { display: block; }
  &.small {
    .right-panel {
      min-width: min(100%, 640px);
      max-width: 40vw;
    }
  }

  &.large {
    .right-panel {
      min-width: min(100%, 800px);;
      max-width: 70vw;
    }
  }
}