fieldset {
  padding-bottom: 1em;
  margin-bottom: 1em;
  border-bottom: solid 1px #eee;
}

.for-table {
  padding: 1em;
  overflow: visible;
}

label.checkbox {
  input[type="checkbox"] {
    margin-right: 0.5em;
  }
}