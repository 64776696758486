.table-footer {
  width: 100%;
}

.table-container {
  width: 100%;
  overflow: auto;
}

@media screen and (min-width: 769px), print {
  .level-left .level-item:not(:last-child), .level-right .level-item:not(:last-child) {
      margin-right: 0rem;
  }
}

@media only screen and (max-width: 768px) {
  .table {
    font-size: 0.8rem;

    .button {
      font-size: 0.8rem !important;
    }
  }

  .mcal-toolbox {
    font-size: 0.75em !important;
  }

  .mcal-button {
    font-size: 0.75em !important;
  }

  .mcal-buttons .field {
    justify-content: center;
  }
}

.table {
  overflow: auto;
}

.financial-year {
  table-layout: fixed;
  width: 100%;
}