
.fp-container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.fp-content {

}

.fp-left {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
}

.fp-top {
  z-index: 2;
  background: white;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}