.duration-picker {
  margin: auto;
  text-align: center;

  .current-duration input {
    font-size: 150%;
    width: auto;
    max-width: 4em;
    text-align: center;
    border: none;
  }

  .duration-minus, .duration-plus {
    font-size: 200%;

    &.enabled {
      cursor: pointer;

      &:hover {
        transform: scale(0.8);
        color: #3273dc;
        transition: all ease-in-out 0.2s;
      }
    }

    &.disabled {
      color: #CCC;
      transform: scale(0.8);
    }

  }
}