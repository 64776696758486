@media only screen and (max-width: 767px) {
  .modal-card-title {
    font-size: 1.25em !important;
    margin-bottom: 0 !important;
  }

  .mcal-booking-slot {
    .level {
      margin-bottom: 0 !important;

      .level-item {
        justify-content: left;
      }

      .level-right {
        display: none;
      }
    }
  }

  .phone-padding {
    padding: 1em;
  }

  .content h2 {
    margin-bottom: 0;
  }
  .level-right {
    margin-top: 0 !important;
  }

}