.notification-container {
  position: fixed;
  bottom: 5%;
  right: 5%;
  width: 33%;
  min-width: 240px;
  z-index: 1001;
}

.message {
  opacity: 1;
  right: 0px;
}

.notification {
  .progress-bar {
    background: blue;
    height: 2px;
    width: 100%;
  }
}

.notification-fade-out {
  transition: all 0.5s ease-in-out;
  position: relative;
  right: -150px;
  opacity: 0;
}

.notification-center-popup {
  position: absolute;
  left: 100%;
  bottom: 0;
  min-width: 240px;
  min-height: 240px;
  width: 33%;
  height: 33%;

  z-index: 10001;

  //background-color: red;
}

.notification-center {
  position: relative;
}

.notification-block {
  flex-grow: 0;
  flex-shrink: 1;
  cursor: pointer;
  padding: 1em;

  border-top: solid 1px #aaa;
  background-color: #666;
  color: white;

  &:hover {
    background-color: #999;
  }
}