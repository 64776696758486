
$link:          #1d4f54;
$grey:	        #a08e86;
$orange:        #FF8B42;
$yellow:        #F9F871;
$green:         #6CBB79;
$turquoise:     #508085;
$cyan:          #526F93;
$blue:          #817CA8;
$purple:        #B386B1;
$red:           #862300;

$grey-light: #e4e0de;

$grey-dark:	darken($grey, 25%);
$grey-darker:	darken($grey, 50%);

$grey-lighter:	lighten($grey, 25%);
$grey-lightest:	lighten($grey, 35%);

// $white-ter:	hsl(0, 0%, 96%);
// $white-bis:	hsl(0, 0%, 98%);
// $white:	hsl(0, 0%, 100%);
// $orange:	hsl(14, 100%, 53%);
// $yellow:	hsl(48, 100%, 67%);
// $green:	hsl(141, 53%, 53%);
// $turquoise:	hsl(171, 100%, 41%);
// $cyan:	hsl(204, 71%, 53%);
// $blue:	hsl(217, 71%, 53%);
// $purple:	hsl(271, 100%, 71%);
// $red:	hsl(348, 86%, 61%);

@import "~bulma/bulma.sass";
@import "~bulma-calendar/dist/css/bulma-calendar.min.css";
@import "~choices.js/public/assets/styles/choices.min.css";


.datetimepicker-dummy .datetimepicker-dummy-wrapper .datetimepicker-dummy-input {
    max-width: 100%;
}

.datetimepicker {
    display: none !important;
    z-index: 1001;

    &.is-active { display: block !important; }
}

@import "./style/calendar.scss";
@import "./style/rulers.scss";
@import "./style/app.scss";

@import "./style/notifications.scss";

@import "./style/top_bar.scss";
@import "./style/form.scss";

@import "./style/sign_in.scss";
@import "./style/right_panel.scss";
@import "./style/bulma_select2.scss";
@import "./style/duration_picker.scss";

@import "./style/print.scss";

@import "./style/time_machine.scss";

@import "./style/kpi.scss";

@import "./style/admin_form.scss";

@import "./style/table.scss";

@import "./style/responsive.scss";
@import "./style/frozen_panes.scss";

body, html {
    display: block;
    position: relative;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    box-sizing: border-box;
}

@supports (-webkit-touch-callout: none) { /* iOS safari mobile rule */
    html {
        height: 100%;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
    }
}

.with-margin {
    margin-left: 0.5em;
    margin-right: 0.5em;
}

.mh10 {
    margin-top: 1em;
    margin-bottom: 1em;
}

td {
  position: relative;
  transition: opacity 2s ease-in-out;
  opacity: 1;
}

.has-overlay td {
    opacity: 0.33;
    &:after {
        content: "";
        position: absolute;
        top: 0px;
        left: 0px;
        width : 100%;
        height: 100%;
        transition: 0.5s ease-in-out;
        background-color: rgba(0,0,0,0);
    }
}

.hint {
    font-size: 0.75em;
    color: #999;
    font-style: italic;
}

.loading-box {
    text-align: center;
}

.white-bg {
    background-color: white;
}