
.card .card-content.kpi {
  text-align: center;

  .kpi-value {
    color: #444;
    font-size: 300%;
  }

  .kpi-label {
    color: #444;
    font-size: 100%;
  }

  .kpi-alt {
    color: #666;
    font-size: 75%;
  }
}