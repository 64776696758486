@import "./_var.scss";

input[type=date] {
  &::-webkit-clear-button {
    display: none;
  }
}

.mcal-widget{
  height: 100%;
  width: 100%;

  display: flex;
  position: relative;
  flex-flow: column;

  .mcal-popover {
    display: block;
    position: absolute;
    min-width: 240px;
    background: rgba(white, 0.95);
    padding: 0.5em;
    font-size: 0.8em;
    border: solid 1px #ccc;
    border-radius: 5px;

    hr {
      margin: 1em;
    }

    p { max-width: 200px; }
  }

  .mcal-toolbox {
    flex-shrink: 1;
  }

  .mcal-toolbox {
    display: flex;
    text-align: center;
    margin-right: 1em;
    margin-left: 1em;
    justify-content: space-between;
    flex-shrink: 0;
  }

  .mcal-booking {
    height: 100%;
    width: 100%;
    margin: 0px;  /*removes default style*/
    display: flex;  /*enables flex content for its children*/
    box-sizing: border-box;

    overflow: auto;

    flex-grow: 1;

    .mcal-booking-container {
        display: flex;  /*enables flex content for its children*/

        height: 100%;
        width: 100%;

    }

    .mcal-column {
      height: 100%;  /*allows both columns to span the full height of the browser window*/
      display: flex;
      flex-direction: column;  /*places the left and right headers above the bottom content*/

      &.mcal-right {
        display: flex;
        flex-grow: 1;

        overflow: visible;

        @media only screen and (max-width: 767px) {
            & > div {
                min-width: 1024px;
            }
        }

        @media only screen and (min-width: 768px) {
            & > div {
                max-width: 100%;
            }
        }
      }
    }

    .mcal-left, .mcal-right, .mcal-top-left {
      flex-shrink: 0;  /*makes sure that content is not cut off in a smaller browser window*/
    }

    .mcal-top-left {
      height: 2.3em;
    }

    .mcal-top-right {
      height: 2.3em;
      flex-shrink: 1;
      flex-grow: 0;

      display: flex;
    }

    .mcal-hours {
      width: 2.5rem;
      font-size: 0.75rem;
    }

    .parent .visible-hover-only {
      display: none;
    }

    a.edit {
      position: absolute;
      right: 2rem;

      color: white;
      font-size: 0.66em;
      line-height: 20px;

      -webkit-appearance: none;
      background-color: rgba(10, 10, 10, 0.2);
      border: none;
      border-radius: 100%;

      cursor: pointer;
      pointer-events: auto;
      display: inline-block;

      height: 20px;
      max-height: 20px;
      max-width: 20px;
      min-height: 20px;
      min-width: 20px;
      width: 20px;

      i {
        margin-left: -5px;
        margin-top: -5px; }

      &:hover { background-color: rgba(10, 10, 10, 0.3); }
    }

    .parent:hover .visible-hover-only {
      display: block;
    }

    .mcal-bottom {
      flex-grow: 1;  /*ensures that the container will take up the full height of the parent container*/
      flex-shrink: 0;

      width: 100%;

      overflow: visible;
    }

    .mcal-booking-room {
      position: relative;

      margin: 0;
      padding: 0;
      box-sizing: border-box;
      width: 100%;
      border-right: solid 1px #aaa;

      &.for-edition {
        background: #fff9cb;
      }

      &:last-child{
        border-right: none;
      }
    }

    .mcal-ruler {
      margin: 0;
      padding: 0;
      display: block;
      width: 100%;
      box-sizing: border-box;

      // &:nth-child(4n),&:nth-child(4n-1)  {
      //   background-color: rgba(0,0,0, 0.05);
      // }

      &.disabled {
        background-color: #eee;
        &:hover { background-color: #fff; }
      }

      &.drag-target {
        border-top: solid 3px #c33 !important;
        //background: #666;
      }

      &:hover {
        background-color: $link;
        transition: all 0.1s ease-out;
      }

      &.mcal-ruler-bold { border-top: solid 1px #ccc; }
      &.mcal-ruler-thin { border-top: dashed 1px #ddd; }
    }

    .mcal-room-titles {
      margin: 0;
      width: 100%;
      text-align: center;
      font-size: 75%;
      box-sizing: border-box;
      height: 3em;

      background-color: white;

      min-width: 900px;

      & > div {
        float: left;
        height: 100%;
        padding: 0;
        border-right: solid 1px #aaa;
        &:last-child {
        border-right: none;
        }
      }
    }

    .mcal-rooms {
      margin: 0;
      padding: 0;
      min-width: 900px;
      height: 900px;
    }

    .mcal-booking-slot {
      position: absolute;
      width: 100%;
      box-sizing: border-box;
      overflow: hidden;
      user-select: none;

      .level {
        margin-bottom: 0.25em;
      }

      .delete {
        position: absolute;
        top: 0.25rem;
        right: 0.25rem;
        color: white;
      }


      &.mcal-booking-active {
        padding: 0.25em;
        background: #0098a7;

        margin: 1px 1px;
        width: calc(100% - 2px);
        border: solid 1px rgba(0,0,0, 0.15);

        border-radius: 4px;
        cursor: pointer;
        color: #333;

        .icon {
          height: 1.25rem;
          width: 1rem;

          .fa-exclamation-triangle {
            color: red;
          }
        }

        div {
          &.time { font-size: 0.5rem; }
          &.name { font-size: 0.65rem; font-weight: bold; }
        }

        p.summary {
          margin-top: 0.25em;
          font-style: italic;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 75%;
        }

        &.mcal-booking-duration-60 { background: #f6e03f; }
        &.mcal-booking-duration-90 { background: #f5d000; }
        &.mcal-booking-duration-120 { background: #cda900; }
        &.mcal-booking-duration-150 { background: #efa389; }
        &.mcal-booking-duration-180 { background: #ed8d6d; }
        &.mcal-booking-duration-240 { background: #d16d2a; }
        &.mcal-booking-duration-270 { background: #c1e0e1; }
        &.mcal-booking-duration-300 { background: #75c9d3; }
        &.mcal-booking-duration-330 { background: #0098a7; }

        &:hover { transition: all ease-in-out 0.05s; background: #ccc; }
      }


      &.mcal-booking-shadow {
        background: #ccc;
        border-radius: 0px;
      }
    }
  }
}


.mcal-hours {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  height: 900px;
  box-sizing: border-box;

  & > div {
    position: relative;
    border-top: solid 1px #ccc;
    border-right: solid 1px #eee;

    font-size: 75%;

    box-sizing: border-box;

    &:first-child {
      border-top: none;
    }

    &:nth-child(2n+1) {
      background-color: white;
  }
    &:nth-child(2n) {
        background-color: #f9f9f9;
    }

    span {
      position: absolute;
      margin: 0;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.mcal-page {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin: 0 !important;

  overflow: hidden;

  .mcal-title {
    width: 100%;
    font-size: 0.75em;

    flex: 0 1 auto;
  }

  .mcal-content {
    flex: 1 1 auto;
    overflow: auto;
  }
}
