@page {
  size: A4;
  margin: 1cm;
}

.print-toolbox {
  background-color: #aaa;
}

.signature-block {
  margin-top: 1cm;
  margin-bottom: 3cm;
}

@media print {
  body, html {
      overflow: auto !important;
      height: auto !important;
      width: auto !important;
  }

  img {
    max-width: 20%;
  }

  .pagebreak {
    page-break-before: always; /* page-break-after works, as well */
  }

  html {
    font-size: 12px;
  }

  .print-toolbox{
    display: none !important;
  }

  #app {
    width: auto          !important;
    height: auto         !important;
    overflow-y: visible  !important;
  }

  .print-container {
    width: auto          !important;
    height: auto         !important;
    overflow-y: visible  !important;
  }
}

footer {
  text-align: center;
  width: 100%;

  position: absolute;
  bottom: 0;
}

.print-container {
  width: 100%;
  height: 100%;

  font-size: 1em;
  overflow-y: auto;
  overflow-x: hidden;

  h2, h3 {
    margin: 1em 0;
  }

  footer {
    display: none;
  }
}

@media print {
  footer {
    font-size: 9px;
    overflow: hidden;
    display: block;
  }

  .content-block, p, h1, h2, h3 {
    page-break-inside: avoid;
  }
}