@media(min-width: 769px) {
  .sign-in {
    width: 100%;
    height: 100%;
    position: relative;
    background: url("/public/sign_in_background.jpg");
    background-size: cover;
    background-position: center center;

    img {
      max-width: 240px;
      margin: auto;
      margin-bottom: 2em;
    }

    .form {
      position: absolute;
      top: 0;
      left: 3%;
      width: 25%;
      min-width: 550px;
      max-width: 600px;
      background-color: rgba(white, 0.95);
      height: 100%;

      padding: 3em;

      border-left: solid 1px #ddd;
      border-right: solid 1px #ddd;
      box-shadow: 10px 10px 15px rgba(black, 0.33);

    }
  }
}

@media(max-width: 768px) {
  .sign-in {
    width: 100%;
    height: 100%;
    position: relative;
    background: none;

    .form {
      position: relative;
      top: 0;

      padding: 3em;

      width: 100%;
      height: 100%;
      background-color: $grey-lightest;

      box-shadow: 10px 10px 15px rgba(black, 0.33);
    }

  }
}

